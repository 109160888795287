import * as React from "react";
import { SvgIcon } from "@mui/material";

const InfoIcon = (props): JSX.Element => {
  return (
    <SvgIcon
      width="20"
      height="20"
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.48603 0 0 4.48604 0 10C0 15.514 4.48603 20 10 20C15.514 20 20 15.514 20 10C20 4.48604 15.514 0 10 0ZM10 1.5C14.7033 1.5 18.5 5.2967 18.5 10C18.5 14.7033 14.7033 18.5 10 18.5C5.29669 18.5 1.5 14.7033 1.5 10C1.5 5.2967 5.29669 1.5 10 1.5ZM10 5C9.73478 5 9.48043 5.10536 9.29289 5.29289C9.10536 5.48043 9 5.73478 9 6C9 6.26522 9.10536 6.51957 9.29289 6.70711C9.48043 6.89464 9.73478 7 10 7C10.2652 7 10.5196 6.89464 10.7071 6.70711C10.8946 6.51957 11 6.26522 11 6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5ZM9.98828 8.48926C9.78954 8.49236 9.60016 8.57423 9.46173 8.71686C9.3233 8.8595 9.24715 9.05125 9.25 9.25V14.75C9.24859 14.8494 9.26696 14.9481 9.30402 15.0403C9.34108 15.1325 9.3961 15.2164 9.46588 15.2872C9.53566 15.358 9.61882 15.4142 9.71051 15.4526C9.8022 15.4909 9.90061 15.5107 10 15.5107C10.0994 15.5107 10.1978 15.4909 10.2895 15.4526C10.3812 15.4142 10.4643 15.358 10.5341 15.2872C10.6039 15.2164 10.6589 15.1325 10.696 15.0403C10.733 14.9481 10.7514 14.8494 10.75 14.75V9.25C10.7514 9.14962 10.7327 9.04997 10.6949 8.95695C10.6571 8.86394 10.6011 8.77946 10.53 8.70852C10.459 8.63758 10.3745 8.58161 10.2814 8.54395C10.1883 8.50629 10.0887 8.48769 9.98828 8.48926Z"
        fill="#262626"
      />
    </SvgIcon>
  );
};
export default InfoIcon;
