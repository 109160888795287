import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { Container, Typography, Box, Stack } from "@mui/material";
import ThumbUpIcon from "../components/icons/ThumbUpIcon";
import Link from "@mui/material/Link";
import { Button } from "gatsby-theme-material-ui";
import InfoIcon from "../components/icons/Info";
import PhoneFunctionsContext from "../context/PhoneFunctionsContext";

export const thankYouTexts = {
  h1: "Objednávka byla úspěšně odeslána!",
  text1:
    "Děkujeme! Vaše objednávka byla zaslána ke zpracování. Brzy Vás budeme kontaktovat. Na e-mail jsme Vám zaslali souhrn objednávky.",
  text2: "Pokud máte jakékoliv dotazy, neváhejte nás kontaktovat na telefon",
  tel: "+420 777 749 598",
  mail: "info@pronajmisito.cz.",
  button: "Zpět do kalkulačky",
};

const ThankYou = () => {
  const { acceptAddToDesktop, pwaAddEvent } = useContext(PhoneFunctionsContext);

  const theme = useTheme();
  return (
    <Container
      maxWidth={false}
      sx={{
        backgroundColor: "background.light",
        pt: 16.8,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {" "}
      <Box
        sx={{
          [theme.breakpoints.up("sm")]: {
            maxWidth: "334px",
            mx: "auto",
          },
        }}
      >
        <Box>
          <Stack alignItems="center">
            <ThumbUpIcon
              color="primary"
              sx={{ width: "112px", height: "112px" }}
            />
          </Stack>
          <Typography variant="h6" sx={{ mt: 6.75 }}>
            {thankYouTexts.h1}
          </Typography>
          <Typography sx={{ mt: 1.5 }}>{thankYouTexts.text1}</Typography>
          <Typography sx={{ mt: 4 }}>
            {thankYouTexts.text2}
            <Link
              href={`tel:${thankYouTexts.tel}`}
              color="text.primary"
              underline="none"
              sx={{ fontWeight: "500" }}
            >
              {thankYouTexts.tel}
            </Link>
            , nebo{" "}
            <Link
              href={`mailto:${thankYouTexts.mail}`}
              color="text.primary"
              underline="none"
              sx={{ fontWeight: "500" }}
            >
              {thankYouTexts.mail}
            </Link>
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 8,
            [theme.breakpoints.up("sm")]: {
              mt: 0,
            },
          }}
        >
          {pwaAddEvent ? (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: "background.default",
                color: "text.light",
              }}
            >
              <InfoIcon
                sx={{
                  ml: 2.25,
                  mr: 1.75,
                  textAlign: "center",
                  justifyItems: "center",
                  justifyContent: "center",
                }}
              />
              <Typography
                sx={{
                  py: 2,
                  pr: 1.5,
                }}
              >
                Pro rychlejší přístup z vašeho zařízení si můžete{" "}
                <Link onClick={acceptAddToDesktop} color="text.light">
                  přidat naší aplikaci
                </Link>{" "}
                na plochu.
              </Typography>
            </Stack>
          ) : null}
          <Button
            to="/"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            sx={{
              mt: 2,
              mb: 5.6,
              [theme.breakpoints.up("sm")]: {
                mt: 4.5,
              },
            }}
          >
            {thankYouTexts.button}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ThankYou;
